.section-width {
  width: 95%;
}

.hero-lite-coin,
.fortune-bitcoin,
.coin-works,
.coin-works-1,
.casino-coin,
.excitement-bitcoin {
  position: absolute;
}

.footer-description {
  padding-left: 100px;
  padding-right: 100px;
}

.prize-width {
  left: calc(50% - 112px);
}

.second-prize-margin {
  margin: 0 20px 0 20px;
}

/* .pl {
  box-shadow: 2em 0 2em rgba(0, 0, 0, 0.2) inset, -2em 0 2em rgba(255, 255, 255, 0.1) inset;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  transform: rotateX(30deg) rotateZ(45deg);
  width: 15em;
  height: 15em;
}

.pl,
.pl__dot {
  border-radius: 50%;
}

.pl__dot {
  animation-name: shadow;
  box-shadow: 0.1em 0.1em 0 0.1em black, 0.3em 0 0.3em rgba(0, 0, 0, 0.5);
  top: calc(50% - 0.75em);
  left: calc(50% - 0.75em);
  width: 1.5em;
  height: 1.5em;
}

.pl__dot,
.pl__dot:before,
.pl__dot:after {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  position: absolute;
}

.pl__dot:before,
.pl__dot:after {
  content: "";
  display: block;
  left: 0;
  width: inherit;
  transition: background-color var(--trans-dur);
}

.pl__dot:before {
  animation-name: pushInOut1;
  background-color: var(--bg);
  border-radius: inherit;
  box-shadow: 0.05em 0 0.1em rgba(255, 255, 255, 0.2) inset;
  height: inherit;
  z-index: 1;
}

.pl__dot:after {
  animation-name: pushInOut2;
  background-color: var(--primary1);
  border-radius: 0.75em;
  box-shadow: 0.1em 0.3em 0.2em rgba(255, 255, 255, 0.4) inset, 0 -0.4em 0.2em #2e3138 inset, 0 -1em 0.25em rgba(0, 0, 0, 0.3) inset;
  bottom: 0;
  clip-path: polygon(0 75%, 100% 75%, 100% 100%, 0 100%);
  height: 3em;
  transform: rotate(-45deg);
  transform-origin: 50% 2.25em;
}

.pl__dot:nth-child(1) {
  transform: rotate(0deg) translateX(5em) rotate(0deg);
  z-index: 5;
}

.pl__dot:nth-child(1),
.pl__dot:nth-child(1):before,
.pl__dot:nth-child(1):after {
  animation-delay: 0s;
}

.pl__dot:nth-child(2) {
  transform: rotate(-30deg) translateX(5em) rotate(30deg);
  z-index: 4;
}

.pl__dot:nth-child(2),
.pl__dot:nth-child(2):before,
.pl__dot:nth-child(2):after {
  animation-delay: -0.1666666667s;
}

.pl__dot:nth-child(3) {
  transform: rotate(-60deg) translateX(5em) rotate(60deg);
  z-index: 3;
}

.pl__dot:nth-child(3),
.pl__dot:nth-child(3):before,
.pl__dot:nth-child(3):after {
  animation-delay: -0.3333333333s;
}

.pl__dot:nth-child(4) {
  transform: rotate(-90deg) translateX(5em) rotate(90deg);
  z-index: 2;
}

.pl__dot:nth-child(4),
.pl__dot:nth-child(4):before,
.pl__dot:nth-child(4):after {
  animation-delay: -0.5s;
}

.pl__dot:nth-child(5) {
  transform: rotate(-120deg) translateX(5em) rotate(120deg);
  z-index: 1;
}

.pl__dot:nth-child(5),
.pl__dot:nth-child(5):before,
.pl__dot:nth-child(5):after {
  animation-delay: -0.6666666667s;
}

.pl__dot:nth-child(6) {
  transform: rotate(-150deg) translateX(5em) rotate(150deg);
  z-index: 1;
}

.pl__dot:nth-child(6),
.pl__dot:nth-child(6):before,
.pl__dot:nth-child(6):after {
  animation-delay: -0.8333333333s;
}

.pl__dot:nth-child(7) {
  transform: rotate(-180deg) translateX(5em) rotate(180deg);
  z-index: 2;
}

.pl__dot:nth-child(7),
.pl__dot:nth-child(7):before,
.pl__dot:nth-child(7):after {
  animation-delay: -1s;
}

.pl__dot:nth-child(8) {
  transform: rotate(-210deg) translateX(5em) rotate(210deg);
  z-index: 3;
}

.pl__dot:nth-child(8),
.pl__dot:nth-child(8):before,
.pl__dot:nth-child(8):after {
  animation-delay: -1.1666666667s;
}

.pl__dot:nth-child(9) {
  transform: rotate(-240deg) translateX(5em) rotate(240deg);
  z-index: 4;
}

.pl__dot:nth-child(9),
.pl__dot:nth-child(9):before,
.pl__dot:nth-child(9):after {
  animation-delay: -1.3333333333s;
}

.pl__dot:nth-child(10) {
  transform: rotate(-270deg) translateX(5em) rotate(270deg);
  z-index: 5;
}

.pl__dot:nth-child(10),
.pl__dot:nth-child(10):before,
.pl__dot:nth-child(10):after {
  animation-delay: -1.5s;
}

.pl__dot:nth-child(11) {
  transform: rotate(-300deg) translateX(5em) rotate(300deg);
  z-index: 6;
}

.pl__dot:nth-child(11),
.pl__dot:nth-child(11):before,
.pl__dot:nth-child(11):after {
  animation-delay: -1.6666666667s;
}

.pl__dot:nth-child(12) {
  transform: rotate(-330deg) translateX(5em) rotate(330deg);
  z-index: 6;
}

.pl__dot:nth-child(12),
.pl__dot:nth-child(12):before,
.pl__dot:nth-child(12):after {
  animation-delay: -1.8333333333s;
}

.pl__text {
  font-size: 0.75em;
  max-width: 5rem;
  position: relative;
  text-shadow: 0 0 0.1em var(--fg-t);
  transform: rotateZ(-45deg);
} */

/* Animations */
@keyframes shadow {
  from {
    animation-timing-function: ease-in;
    box-shadow: 0.1em 0.1em 0 0.1em black, 0.3em 0 0.3em rgba(0, 0, 0, 0.3);
  }

  25% {
    animation-timing-function: ease-out;
    box-shadow: 0.1em 0.1em 0 0.1em black, 0.8em 0 0.8em rgba(0, 0, 0, 0.5);
  }

  50%,
  to {
    box-shadow: 0.1em 0.1em 0 0.1em black, 0.3em 0 0.3em rgba(0, 0, 0, 0.3);
  }
}

@keyframes pushInOut1 {
  from {
    animation-timing-function: ease-in;
    background-color: var(--bg);
    transform: translate(0, 0);
  }

  25% {
    animation-timing-function: ease-out;
    background-color: var(--primary2);
    transform: translate(-71%, -71%);
  }

  50%,
  to {
    background-color: var(--bg);
    transform: translate(0, 0);
  }
}

@keyframes pushInOut2 {
  from {
    animation-timing-function: ease-in;
    background-color: var(--bg);
    clip-path: polygon(0 75%, 100% 75%, 100% 100%, 0 100%);
  }

  25% {
    animation-timing-function: ease-out;
    background-color: var(--primary1);
    clip-path: polygon(0 25%, 100% 25%, 100% 100%, 0 100%);
  }

  50%,
  to {
    background-color: var(--bg);
    clip-path: polygon(0 75%, 100% 75%, 100% 100%, 0 100%);
  }
}

@media (min-width: 1601px) {
  .section-width {
    max-width: 1410px;
  }

  .bg-1 {
    height: 930px;
  }

  .bg-2 {
    height: 930px;
    top: 800px;
  }

  .how-it-work-bg-1 {
    height: 930px;
    top: 1800px;
  }

  .how-it-work-bg-2 {
    height: 930px;
    top: 2600px;
  }

  .join-bg {
    height: 930px;
    top: 4700px;
  }

  .referral-bg {
    height: 930px;
  }

  .host-bg {
    height: 930px;
  }

  .secure-bg {
    height: 930px;
  }

  .faqs-bg-1 {
    top: 8200px;
  }

  .footer-bg {
    top: 9250px;
  }

  .hero-lite-coin {
    top: -215px;
    left: 310px;
  }

  .fortune-bitcoin {
    width: 160px;
    top: 230px;
    right: -40px;
  }

  .participate-coin {
    right: -110px;
    bottom: -30px
  }

  .how-it-work-coin-1 {
    top: 450px;
    right: -130px;
  }

  .how-it-work-coin-2 {
    right: -130px;
    bottom: 10px;
  }

  .how-it-coin-work {
    height: 230px;
    bottom: 675px;
    left: -130px;
  }

  .coin-works {
    height: 230px;
    top: 70px;
    left: -100px;
  }

  .coin-works-1 {
    height: 385px;
    right: -155px;
    bottom: -50px;
  }

  .host-coin-work {
    height: 230px;
    right: -150px;
    top: 330px;
  }

  .casino-coin {
    top: -77px;
    left: -151px;
  }

  .excitement-bitcoin {
    top: -75px;
    left: -95px
  }

  .casino-coin-refferal {
    top: 410px;
    left: -180px;
  }

  .faqs-coin-work-1 {
    height: 230px;
    top: 105px;
    left: -115px;
  }

  .faqs-coin-work-2 {
    height: 350px;
    top: 475px;
    right: -155px;
  }

  .faqs-casino-coin {
    top: 900px;
    left: -140px;
  }

  .faqs-participate-coin {
    right: -120px;
    bottom: 335px;
  }

  .technical-coin {
    height: 230px;
    bottom: 152px;
    left: -115px;
  }

  .excitement-card {
    padding: 60px;
  }

  .bitcoin-card {
    padding: 60px;
  }

  .secure-card {
    padding: 60px;
  }

  .works-height {
    height: 500px;
  }
}

@media (max-width: 1600px) {
  .section-width {
    max-width: 1210px;
  }

  .bg-1 {
    height: 800px;
  }

  .bg-2 {
    height: 800px;
    top: 800px;
  }

  .how-it-work-bg-1 {
    height: 800px;
    top: 1800px;
  }

  .how-it-work-bg-2 {
    height: 800px;
    top: 2600px;
  }

  .join-bg {
    height: 800px;
    top: 4700px;
  }

  .referral-bg {
    height: 800px;
  }

  .host-bg {
    height: 800px;
  }

  .secure-bg {
    height: 800px;
  }

  .faqs-bg-1 {
    height: 800px;
    top: 8200px;
  }

  .footer-bg {
    height: 800px;
    top: 9250px;
  }

  .hero-lite-coin {
    top: -130px;
    left: 230px;
  }

  .fortune-bitcoin {
    width: 160px;
    top: 180px;
    right: -40px;
  }

  .participate-coin {
    display: none;
  }

  .coin-works {
    height: 210px;
    top: 85px;
    left: -80px;
  }

  .coin-works-1 {
    height: 256px;
    right: -120px;
    bottom: -90px;
  }

  .host-coin-work {
    height: 230px;
    top: 330px;
    right: -115px;
  }

  .how-it-work-coin-1 {
    top: 450px;
    right: -100px;
  }

  .how-it-work-coin-2 {
    right: -100px;
    bottom: 10px;
  }

  .how-it-coin-work {
    height: 230px;
    bottom: 675px;
    left: -100px;
  }

  .casino-coin {
    top: -90px;
    left: -130px;
  }

  .excitement-bitcoin {
    top: -75px;
    left: -50px
  }

  .casino-coin-refferal {
    top: 460px;
    left: -135px;
  }

  .faqs-coin-work-1 {
    height: 210px;
    top: 105px;
    left: -100px;
  }

  .faqs-coin-work-2 {
    height: 255px;
    top: 550px;
    right: -120px;
  }

  .faqs-casino-coin {
    height: 200px;
    top: 940px;
    left: -115px;
  }

  .faqs-participate-coin {
    height: 200px;
    right: -100px;
    bottom: 375px;
  }

  .technical-coin {
    height: 210px;
    bottom: 160px;
    left: -100px;
  }

  .excitement-card {
    padding: 60px;
  }

  .bitcoin-card {
    padding: 60px;
  }

  .secure-card {
    padding: 60px;
  }

  .works-height {
    height: 450px;
  }
}

@media (max-width: 1440px) {
  .section-width {
    max-width: 1050px;
  }

  .bg-1 {
    height: 800px;
  }

  .bg-2 {
    height: 800px;
    top: 800px;
  }

  .how-it-work-bg-1 {
    height: 800px;
    top: 1800px;
  }

  .how-it-work-bg-2 {
    height: 800px;
    top: 2600px;
  }

  .join-bg {
    height: 800px;
    top: 4700px;
  }

  .referral-bg {
    height: 800px;
    top: 5500px;

  }

  .host-bg {
    height: 800px;
    top: 6400px;
  }

  .secure-bg {
    height: 800px;
  }

  .faqs-bg-1 {
    height: 800px;

  }

  .footer-bg {
    height: 800px;

  }

  .hero-lite-coin {
    top: -130px;
    left: 230px;
  }

  .fortune-bitcoin {
    width: 120px;
    top: 165px;
    right: -25px;
  }

  .participate-coin {
    display: none;
  }

  .coin-works {
    height: 210px;
    top: 70px;
    left: -50px;
  }

  .coin-works-1 {
    height: 255px;
    right: -30px;
    bottom: -120px;
  }

  .host-coin-work {
    height: 230px;
    top: 330px;
    right: -50px;
  }

  .casino-coin {
    height: 210px;
    top: -100px;
    left: -60px;
  }

  .how-it-work-coin-1 {
    top: 450px;
    right: -40px;
  }

  .how-it-work-coin-2 {
    right: -40px;
    bottom: 10px;
  }

  .how-it-coin-work {
    height: 230px;
    bottom: 675px;
    left: -50px;
  }

  .excitement-bitcoin {
    height: 115px;
    width: 105px;
    top: -55px;
    left: -50px;
  }

  .casino-coin-refferal {
    top: 460px;
    left: -90px;
  }

  .faqs-coin-work-1 {
    height: 210px;
    top: 105px;
    left: -50px;
  }

  .faqs-coin-work-2 {
    height: 255px;
    top: 550px;
    right: -70px;
  }

  .faqs-casino-coin {
    height: 200px;
    top: 940px;
    left: -80px;
  }

  .faqs-participate-coin {
    height: 200px;
    right: -80px;
    bottom: 375px;
  }

  .technical-coin {
    height: 210px;
    bottom: 160px;
    left: -50px;
  }

  .excitement-card {
    padding: 60px;
  }

  .bitcoin-card {
    padding: 60px;
  }

  .secure-card {
    padding: 60px;
  }

  .works-height {
    height: 450px;
  }
}

@media (max-width:1200px) {

  .section-width {
    max-width: 900px;
  }

  .hero-lite-coin {
    top: -130px;
    left: 230px;
  }

  .fortune-bitcoin {
    width: 120px;
    top: 165px;
    right: -25px;
  }

  .participate-coin {
    display: none;
  }

  .coin-works {
    height: 210px;
    top: 70px;
    left: -50px;
  }

  .coin-works-1 {
    height: 255px;
    right: -30px;
    bottom: -120px;
  }

  .casino-coin {
    height: 210px;
    top: -100px;
    left: -60px;
  }
}

@media (max-width: 1024px) {
  .section-width {
    max-width: 850px;
  }

  .fortune-bitcoin {
    top: 150px;
    right: -36px;
  }

  .participate-coin {
    display: none;
  }

  .how-it-work-coin-1 {
    display: none;
  }

  .how-it-work-coin-2 {
    display: none;
  }

  .excitement-bitcoin {
    left: -35px;
    top: -65px;
  }

  .footer-description {
    padding-left: 50px;
    padding-right: 50px;
  }

  .excitement-card {
    padding: 40px;
  }

  .bitcoin-card {
    padding: 40px;
  }

  .secure-card {
    padding: 40px;
  }

  .works-height {
    height: 100%;
  }

}

@media (max-width:1023px) {
  .second-prize-margin {
    margin: 20px 20px 0 0px;
  }

  .third-prize-margin {
    margin: 20px 0 0 0;
  }
}

@media (max-width: 768px) {
  .section-width {
    max-width: 700px;
  }

  .works-height {
    height: 100%;
  }

}

@media (max-width: 640px) {

  .section-width {
    max-width: 600px;
  }

  .footer-description {
    padding-left: 10px;
    padding-right: 10px;
  }

  .works-height {
    height: 100%;
  }
}